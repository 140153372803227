<template>
  <div class="row happy" id="fsl">
    <div class="row">
      <div class="row">
        <h3>For Parents</h3>
      </div>
      <div class="row desc">
        <h5>
          Empowers parents for a better engagement and involvement into their children's learning and development
        </h5>
      </div>
    </div>
    <div class="row environments">
      <!-- <img class="bgback" src="@/assets/graphics/outline3.svg" /> -->
      <div class="row">
        <div class="row tab">
          <div class="row">
            <img class="icon" src="@/assets/graphics/school_community_portal_access.svg" />
          </div>
          <div class="row settitle">
            <p>School Community<br>Portal Access</p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon" src="@/assets/graphics/comprehensive_child_monitoring.svg" />
          </div>
          <div class="row settitle">
            <p>Comprehensive<br>Child Monitoring</p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon" src="@/assets/graphics/online_payment_and_balance_monitoring.svg" />
          </div>
          <div class="row settitle">
            <p>Online Payment<br>& Balance Monitoring</p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon" src="@/assets/graphics/family_account_management.svg" />
          </div>
          <div class="row settitle">
            <p>Family Account<br>Management</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row carousel">
        <div class="gallery js-flickity" data-flickity-options='{ "wrapAround": true }'>
            <div class="gallery-cell">
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/graphics/school_community_portal_access.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>School Community Portal Access</h1>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/graphics/comprehensive_child_monitoring.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Comprehensive Child Monitoring</h1>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/graphics/online_payment_and_balance_monitoring.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Online Payment & Balance Monitoring</h1>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/graphics/family_account_management.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Family Account Management</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  *{
    color: #094000;
  }
  .happy{
    margin: 0;
    overflow: hidden;
  }
  #fsl{
    padding-top: 50px;
  }
  h3{
    margin-top: 3vh;
    font-weight: 900;
  }
  .students{
    padding: 5vh 7vw;
  }
  .tab{
    width: 25%;
  }

  .environments{
    padding: 5vh 10vw;
    position: relative;
  }
  .desc{
    margin-top: 3vh;
  }

  .icon{
    width: 20vw;
    max-height: 20vh;
  }

  .row.tab > .row{
    justify-content: center;
  }

  .hrarea{
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 4vh;
  }

  .row > *{
    /* padding-right: unset;
    padding-left: unset; */
    justify-content: center;
  }

  .cover{
    padding-bottom: 1vh;
  }

  span{
    padding: 1vh 0vw;
    font-weight: 700;
  }

  .ind > .icon{
    width: 5vw;
  }

  .ind{
    height: 6vh;
  }

  .-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 30%;
    margin: 0;
    padding: 0;
    padding-top: 2vh;
    margin-left: 2vw;
  }

  .settitle > p{
    margin-top: 2vh;
    font-family: MontserratBold, sans-serif;
  }
  .bgback{
    width: 50%;
    position: absolute;
    /* z-index: ; */
    margin-top: -30vh;
    left: -30%;
    opacity: 30%;
  }
  
  /* start here */
  .carousel{
      padding: 0px;
      margin: 0;
      width: 100%;
      display: none;
  }
  .gallery {
      /* background: #fff; */
      height: 250px;
      padding-right: unset !important;
      padding-left: unset !important;
      margin-left: unset !important;
      margin-right: unset !important;
  }
  .gallery-cell {
      width: 30%;
      /* height: 100px; */
      margin-right: 60px;
      /* background: #8C8; */
      counter-increment: gallery-cell;
      /* box-shadow: -5px 5px 25px #00000029; */
  }

  /* cell number */
  .gallery-cell:before {
      display: block;
      text-align: center;
      /* content: counter(gallery-cell); */
      line-height: 200px;
      font-size: 80px;
      color: white;
  }
  .flickity-page-dots{
      left: 0%;
      width: 100%;
      bottom: -37px;
      display: none;
  }
  .flickity-page-dots .dot{
      background: #fff;
      border: 1px solid #707070;
      width: 13.5px;
      height: 13.5px;
  }
  .flickity-page-dots .dot.is-selected {
      background: #3BE03B;
  }
  .flickity-button, .flickity-button-icon{
      visibility: hidden !important;
  }


  .gallery-desc > .desc-item > .icon{
    margin-top: 38px;
    height: 100px;
    width: 100%;
  }

  .gallery-desc > .desc-item > h1{
    font-size: 13px;
  }

  .is-selected > .gallery-desc > .desc-item > h1{
    font-size: 16px;
  }

  .is-selected > .gallery-desc > .desc-item > .icon{
    height: 130px;
    width: 100%;
    margin-top: 0px;
  }
  .cell-main-img{
      width: 100%;
      height: 188px;
  }
  .gallery-desc{
      /* position: absolute; */
      /* width: 120%;
      left: -10%; */
      padding: 10px 0px;
  }
  .gallery-cell>.gallery-desc{
      opacity: 0.5;
      transition: 2s;
  }
  .is-selected>.gallery-desc{
      opacity: 1;
  }
  .gallery-desc>.desc-item{
      width: 100%;
      padding: 0px;
  }
  .desc-item>.icon{
      margin-top: 40px;
      margin-bottom: 10px;
      height: 23px;
  }
  .desc-item>h1{
      font-size: 20px;
      font-weight: 600;
  }
  .desc-item>h2{
      font-size: 13px;
      color: #707070;
  }
  .desc-item>.viewFeatures{
      text-align: left;
  }
  .desc-item>.viewFeatures>u{
      font-size: 13px;
      font-weight: 700;
      color: #2F2F2F;
  }
  .desc-item>ul{
      list-style: none;
      text-align: left;
      font-size: 13px;
      font-weight: 600;
  }

  @media only screen and (max-width: 1024px) {
    .happy{
      padding: 30px 0px !important;
    }

    h3{
      margin-top: 0px;
      margin-bottom: 0px;
      color: #201e21;
      font-size: 30px;
      font-weight: 800;
    }
    
    .desc{
      margin-top: 10px;
      padding: 0px 15vw;
    }

    h5{
      margin-top: 0px;
      color: #201023;
    }

    .environments{
      display: none;
    }
    
    .carousel{
      display: block;
    }
  }
</style>
