<template>
  <div class="row let">
    <div class="row" style="padding-top: 8rem">
      <h1>BECOME A MEMBER</h1>
    </div>
    <div class="row main-featured">
      <div class="row">
        <h2 style="text-align: left"><b>New Member Registration</b></h2>
        <h2 style="text-align: left">Payment Methods</h2>
      </div>
    </div>

    <div class="row container">
      <div class="icon-div">
      </div>
      <div class="icon-div">
      </div>
      <div class="text-area">
        <img class="icon-img" src="@/assets/icons/ctbc.png" />
        <h3>via CTBC Bank Las Pinas Branch</h3>
        <br>
        <p>Acct. Name: Association of Las<br>Pinas Private Schools ( ALPPS)<br>Account Number: 2001600103-5</p>
      </div>
      <div class="text-area">
        <img class="icon-img" src="@/assets/icons/gcash.png" />
        <h3>via Cash Bank Transfer</h3>
        <br>
        <p>Bank Transfer to: CTBC Bank (Phil Corp.)<br>Account Name: ALPPS<br>Account Number: 020016001035</p>
      </div>
    </div>

    <div class="row container">
      <div class="col-md-30">
        <img class="icon-img" src="@/assets/icons/undraw-receipt.png" />
      </div>
      <div class="col-md-70 note">
        <h4>Deposit slips / proofs of payment must be scanned and emailed to <b>bongaguirre40.ba@gmail.com</b><br><br> Please indicate the name of your school in the deposit slip.</h4>
      </div>
    </div>
    <div class="row container">
      <div class="full-width">
        <h4><b>Please complete the form to register your school as a<br>member of ALPPS for School Year 2021-2022</b></h4>
        <a class="form-btn" href="new-membership" >PROCEED TO FORM</a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TopNote",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  /* color: #fff; */
  vertical-align: text-top;
}

.main-featured {
  padding: 2rem 4vw 0 4vw;
}

.other-evt-vid {
  padding: 2vh;
}

.icon {
  height: 100%;
}

.container{
  padding: 4rem 0 2rem 0;
}

.container, .offer-select{
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.offer-select{
  padding: 4vh;
}

.icon-div, .text-area{
  width: 50%;
}
.title-div{
  width: 70%;
  padding-right: 10vw;
  align-self: center;
}

.container > .icon-div > img {
  width: 20.5rem;
}

.icon-img{
  max-width: 250px;
}

.col-md-30{
  width: 30%;
}

.col-md-70{
  width: 70%;
}

.note{
  padding: 1rem 3rem;
  background: #EDEEFF;
}

.col-md-30 > img{
  width: 50%;
}

.fullwidth{
  width: 100%;
}

h1,
h2 {
  color: #EFD1A4;
}
.let {
  /* background: #2E3192; */
  margin: 0;
}

h1 {
  font-size: 2em;
  font-family: MontserratBold, sans-serif;
}

.note > h4 {
  text-align: left;
}

.row > * {
  /* padding-right: unset;
    padding-left: unset; */
  justify-content: center;
}

.form-btn{
  background: #EFD1A4;
  padding: .5rem 1.5rem;
  border-radius: 6px;
  color: #fff;
  font-family: MontserratExtraBold;
  display: block;
  width: 240px;
  margin: auto;
  margin-top: 2rem;
}
@media only screen and (max-width: 1024px) {
  .icon-div,
  .text-area{
    width: 100%;
    padding: 0 1rem;
  }

  .note{
    padding: .5rem 1rem;
  }

  .col-md-30,
  .col-md-70{
    width: 100%;
  }
}
</style>
