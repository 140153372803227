<template>
  <div class="row let">
    <div class="row">
      <h2>Achieve more with less work.</h2>
    </div>
    <div class="row desc" style="justify-content: center;">
      <h4>
        with white<b>Strings</b> Platform achieve more by doing less with tasks that are not related to learning.
      </h4>
      <h4 class="wedo">
        We do the hard work for you.
      </h4>
    </div>
    <div class="row btn-area">
      <a href="/book">Schedule a Demo Now</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  *{
    color: #073700;
  }

  .let{
    background: #e3fdd9;
    padding: 8vh 0vh;
    margin: 0;
  }

  h2{
    font-size: 2em;
    font-family: MontserratBold, sans-serif;
  }

  h4{
    padding: 3vh;
    width: 50vw;
    font-size: 1em;
  }

  .btn-area{
    justify-content: center;
  }

  a{
    font-family: MontserratBold, sans-serif;
    margin-top: 1vh;
    padding: 10px 20px;
    width: auto;
    font-size: 15px;
    cursor: pointer;
    color: #fff !important;
    background: #f43ba9;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1024px) {
    .let{
      padding: 6vh 15vw;
      text-align: left;
    }

    h2{
      font-size: 30px;
      color: #000;
    }

    .desc{
      padding: 0px;
    }

    h4{
      margin-top: 10px;
      width: 100%;
      padding: 0px;
      font-size: 20px;
      font-weight: 400;
      color: #000;
    }
    
    .wedo{
      margin-top: 10px;
    }
    
    .btn-area{
      margin-top: 20px;
    }

    a{
      background: #71f6ac;
    }
  }
</style>
