<template>
  <div id="app">
    <Header/>
      <router-view/>
    <Footer/>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'default',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
@import './assets/styles/main.css';

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"), url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"), url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"), url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}


#nav > a {
  font-weight: bold;
  color: #2c3e50;
}

.row > * {
    /* --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y); */
}

.row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    /* display: flex;
    flex-wrap: wrap; */
    margin-top: calc(-1 * var(--bs-gutter-y)) ;
    margin-right: calc(-0.5 * var(--bs-gutter-x)) ;
    margin-left: calc(-0.5 * var(--bs-gutter-x)) ;
}

.carousel{
  overflow: hidden;
  overflow-x: clip !important;
}

</style>
