<template>
  <div class="row let" id="contactus">
    <div class="row">
      <h1>CONTACT US</h1>
    </div>
    <div class="row" style="justify-content: center">
      <div class="map">
        <!-- <div class="mapouter"> -->
        <div class="gmap_canvas">
          <iframe
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=7%20rosal,%20las%20pi%C3%B1as&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
        <!-- </div> -->
      </div>
      <div class="contact">
        <div class="contact-details">
          <div class="contact-icon">
            <div class="icon">
              <img class="icon-img" src="@/assets/icons/home-ico-black.png" />
            </div>
          </div>
          <div class="contact-info">
            <div class="contact-title">
              <h2><b>Address</b></h2>
            </div>
            <div class="contact-desc">
              <h2>Las Piñas Montessori School, LPMS Campus, 25 Gumamela St., Vergonville Subd., Las Piñas City</h2>
            </div>
          </div>
        </div>

        <div class="contact-details">
          <div class="contact-icon">
            <div class="icon">
              <img class="icon-img" src="@/assets/icons/phone-ico-black.png" />
            </div>
          </div>
          <div class="contact-info">
            <div class="contact-title">
              <h2><b>Contact Number</b></h2>
            </div>
            <div class="contact-desc">
              <h2>8846-2399 / 8836-0720</h2>
            </div>
          </div>
        </div>

        <div class="contact-details">
          <div class="contact-icon">
            <div class="icon">
              <img class="icon-img" src="@/assets/icons/mail-ico-black.png" />
            </div>
          </div>
          <div class="contact-info">
            <div class="contact-title">
              <h2><b>Email</b></h2>
            </div>
            <div class="contact-desc">
              <h2>info@alpps.ph</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNote",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: #000;
  vertical-align: text-top;
}

.map,
.contact {
  display: inline-block;
  text-align: -webkit-center;
}

.map {
  padding: 3vh 0vw 3vh 4vw;
  width: 50%;
}

.contact {
  width: 50%;
  padding-top: 1rem;
}

.icon {
  height: 100%;
}

.icon-img {
  width: 70px;
}

.contact-details {
  width: 100%;
  margin-top: 20px;
}

.contact-icon,
.contact-info {
  display: inline-block;
}

.contact-icon {
  width: 20%;
}
.contact-info {
  padding-top: 0px;
  width: 70%;
}

h2 {
  text-align: left;
}

.let {
  background: #EFD1A4;
  padding: 8vh 0vh;
  margin: 0;
}

h1 {
  font-size: 2em;
  font-family: MontserratBold, sans-serif;
}

h4 {
  padding: 3vh;
  width: 50vw;
  font-size: 1em;
}

.btn-area {
  justify-content: center;
}

a {
  font-family: MontserratBold, sans-serif;
  margin-top: 1vh;
  padding: 10px 20px;
  width: auto;
  font-size: 15px;
  cursor: pointer;
  color: #fff !important;
  background: #f43ba9;
  border-radius: 5px;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 600px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  box-shadow: 12px 12px #f2ba60;
}

#gmap_canvas{
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  * {
    color: #000;
  }

  .let {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  h1 {
    padding-bottom: 20px;
  }

  h4 {
    padding: 20px 0px;
    width: 100%;
    font-size: 20px;
  }

  .btn-area > a {
    font-size: 17px;
    font-weight: 600;
  }
  .map {
    width: 100%;
    padding: 0px;
  }

  .contact-icon {
    width: 20%;
    padding-right: 15px;
  }
  .contact-info {
    width: 80%;
  }

  #gmap_canvas {
    width: 100%;
  }

  .gmap_canvas {
    width: 100%;
    height: auto;
    box-shadow: 6px 6px #f2ba60;
  }
  .contact {
    width: 100%;
  }
}
</style>
