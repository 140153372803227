<template>
  <div class="row let">
    <div class="row" style="padding-top: 8rem">
      <h1>BECOME A MEMBER</h1>
    </div>
    <div class="row main-featured">
      <div class="row">
        <h2 style="text-align: left"><b>Our Partner Schools</b></h2>
      </div>
      
      <div class="row carousel">
          <div class="gallery js-flickity" data-flickity-options='{ "wrapAround": true }'>
              <div class="gallery-cell">
                  <div class="gallery-desc">
                      <div class="desc-item">
                          <img class="icon" src="https://drive.google.com/uc?export=view&id=1tozixZG52-zpXDcomIWYoyoQ6xpkh7R9" />
                      </div>
                      <div class="desc-item">
                          <h1>Las Piñas Montessori</h1>
                      </div>
                  </div>
              </div>
              <div class="gallery-cell">
                  <div class="gallery-desc">
                      <div class="desc-item">
                          <img class="icon" src="https://drive.google.com/uc?export=view&id=10-Iich3qn8qJOr3hZ0rlbqraT7tVBH2J" />
                      </div>
                      <div class="desc-item">
                          <h1>South Learners Creative School</h1>
                      </div>
                  </div>
              </div>
              <div class="gallery-cell">
                  <div class="gallery-desc">
                      <div class="desc-item">
                          <img class="icon" src="https://drive.google.com/uc?export=view&id=1-e0e9dMIjVCYL1i_bKaDPjYgIo3cSaFS" />
                      </div>
                      <div class="desc-item">
                          <h1>Jesus Cares Christian Academy</h1>
                      </div>
                  </div>
              </div>
              <div class="gallery-cell">
                  <div class="gallery-desc">
                      <div class="desc-item">
                          <img class="icon" src="https://drive.google.com/uc?export=view&id=18c-Lc9Zke9L-ZutEivd3vvStUBFjXPAL" />
                      </div>
                      <div class="desc-item">
                          <h1>Academy of Jesus</h1>
                      </div>
                  </div>
              </div>
              <div class="gallery-cell">
                  <div class="gallery-desc">
                      <div class="desc-item">
                          <img class="icon" src="https://drive.google.com/uc?export=view&id=1tozixZG52-zpXDcomIWYoyoQ6xpkh7R9" />
                      </div>
                      <div class="desc-item">
                          <h1>Las Piñas Montessori</h1>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </div>

    <div class="row icon-title">
      <div class="icon-div">
        <img class="icon-img" src="@/assets/icons/laprisa.png" />
      </div>
      <div class="title-div">
        <h1><b>ALPPS REGISTRATION SY 2021-2022<br>"WE STAND FOR INNOVATION!"</b></h1>
      </div>
    </div>

    <div class="row offer-select">
      <div class="row fullwidth">
        <h2><b>Select your subscription</b></h2><br>
        <h3>Join the ALPPS Family now.</h3>
      </div>
      <div class="new-div">
        <div class="cont">
          <h3><b>NEW MEMBER</b></h3><br>
          <span class="php">PHP</span> <span class="amt">1500</span><br>
          <span class="check">✓</span><span class="annum">Annually</span><br>
          <a class="reg" href="new-membership" >REGISTER</a>
        </div>
      </div>
      <div class="renew-div">
        <div class="cont">
          <span class="perks">Perks ;)</span>
          <h3><b>RENEWAL</b></h3><br>
          <span class="php">PHP</span> <span class="amt">1000</span><br>
          <span class="check">✓</span><span class="annum">Annually</span><br>
          <a class="ren" href="renew-membership" >RENEW</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNote",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  /* color: #fff; */
  vertical-align: text-top;
}

.main-featured {
  padding: 0 4vw;
}

.other-evt-vid {
  padding: 2vh;
}

.carousel{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.video,
.event {
  display: inline-block;
  text-align: -webkit-center;
}

.video {
  padding: 3vh 0vw 3vh 4vw;
  width: 50%;
}

.event {
  width: 50%;
  padding-top: 10vh;
}

.icon {
  height: 100%;
}

.icon-title{
  padding: 6rem 0;
}

.icon-title, .offer-select{
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.offer-select{
  padding: 4vh;
}

.icon-div{
  width: 30%;
  text-align: -webkit-right;
}
.title-div{
  width: 70%;
  padding-right: 10vw;
  align-self: center;
}

.icon-title > .icon-div > img {
  width: 10.5rem;
}

.fullwidth{
  width: 100%;
}

.new-div,
.renew-div{
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 50%;
  max-width: 500px;
}
.new-div{
  padding-left: 10vw;
  padding-right: 2.5vw;
} 
.renew-div{
  padding-right: 10vw;
  padding-left: 2.5vw;
}

.cont{
  text-align: center;
  padding: 30px;
  background: #fff;
  padding: 3rem 0;
  border-radius: 4px;
  box-shadow: 12px 12px #F2BA60;
}

.cont > h3 {
  font-family: MontserratExtraBold;
  font-size: 2rem;
  color: #EFD1A4;
}
.cont > .php {
  line-height: 2rem;
  vertical-align: bottom;
}
.cont > .amt {
  font-family: MontserratExtraBold;
  font-size: 2rem;
}

.cont > .check{
  color: #81EB68;
  padding-right: 1em;
  font-size: 1.5em;
}
.cont > .annum{
  font-size: 1.5em;
}

.reg,
.ren{
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 8px;
  font-weight: 900;
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  display: block;
}

.reg{
  background: #EFD1A4;
  border: 2px solid #EFD1A4;
  color: #fff;
}

.ren{
  border: 2px solid #EFD1A4 !important;
  color: #EFD1A4 !important;
  background: #fff;
}

.perks{
  background: #FF6043;
  color: #fff;
  padding: 0.5rem 1.5rem;
  position: absolute;
  font-size: 1.5rem;
  margin-left: 6rem;
  margin-top: -2.6rem;
}

.event-details {
  width: 100%;
  margin-top: 20px;
}

.event-icon,
.event-info {
  display: inline-block;
}

.event-icon {
  width: 20%;
}
.event-info {
  padding-top: 0px;
  width: 70%;
}

h1,
h2 {
  color: #EFD1A4;
}

.offer-select{
  background: #EFD1A4;
  padding: 4rem;
}

.offer-select > .row > h2,
.offer-select > .row > h3{
  color: #fff;
}

.fullwidth > h2,
.fullwidth > h3{
  text-align: left;
}

.let {
  /* background: #2E3192; */
  margin: 0;
}

h1 {
  font-size: 2em;
  font-family: MontserratBold, sans-serif;
}

h4 {
  text-align: left;
  /* padding: 3vh;
    width: 50vw;
    font-size: 1em; */
}

.btn-area {
  justify-content: center;
}

.gallery {
      background: #fff;
      height: 188px;
      padding-right: unset !important;
      padding-left: unset !important;
      margin-left: unset !important;
      margin-right: unset !important;
}

.gallery-cell {
    width: 18.8%;
    /* height: 100px; */
    margin-right: 20px;
    /* background: #8C8; */
    counter-increment: gallery-cell;
    /* box-shadow: -5px 5px 25px #00000029; */
}

/* cell number */
.gallery-cell:before {
    display: block;
    text-align: center;
    /* content: counter(gallery-cell); */
    line-height: 200px;
    font-size: 80px;
    color: #000;
}
.flickity-page-dots{
    left: 0%;
    width: 100%;
    bottom: -37px;
    /* display: none; */
}
.flickity-page-dots .dot{
    background: #000;
    border: 1px solid #707070;
    width: 13.5px;
    height: 13.5px;
}
.flickity-page-dots .dot.is-selected {
    background: #3BE03B;
}
.flickity-button, .flickity-button-icon{
    /* visibility: hidden !important; */
}

.flickity-prev-next-button{
  /* visibility: hidden; */
}

.gallery-desc > .desc-item > .icon{
  margin-top: 30px;
  height: 100px;
  width: auto;
}

.gallery-desc > .desc-item > h1{
  font-size: 15px;
}

.is-selected > .gallery-desc > .desc-item > h1{
  font-size: 20px;
}

.is-selected > .gallery-desc > .desc-item > .icon{
  height: 120px;
  width: auto;
  margin-top: 0px;
}
.cell-main-img{
    width: 100%;
    height: 188px;
}
.flickity-viewport{
  height: unset !important;
  overflow: unset !important;
  overflow-x: clip;
}
.gallery-desc{
    /* position: absolute; */
    /* width: 120%;
    left: -10%; */
    padding: 10px 0px;
}
.gallery-cell>.gallery-desc{
    opacity: 0.5;
    transition: 2s;
}
.is-selected>.gallery-desc{
    opacity: 1;
}
.gallery-desc>.desc-item{
    width: 100%;
    padding: 0px;
}
.desc-item>.icon{
    margin-top: 40px;
    margin-bottom: 10px;
    height: 23px;
}
.desc-item>h1{
    font-size: 20px;
    font-weight: 600;
}
.desc-item>h2{
    font-size: 13px;
    color: #707070;
}
.desc-item>.viewFeatures{
    text-align: left;
}
.desc-item>.viewFeatures>u{
    font-size: 13px;
    font-weight: 700;
    color: #2F2F2F;
}
.desc-item>ul{
    list-style: none;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}


@media only screen and (max-width: 1024px) {
}

.happy {
  margin: 0;
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.elected {
  font-weight: 900;
  color: #EFD1A4;
}
.students {
  padding: 5vh 7vw;
}
.tab {
  width: 19.3%;
}

.environments,
.industries {
  padding: 5vh 10vw;
}

.icon {
  width: 20vw;
}

.row.tab > .row {
  justify-content: center;
}

.hrarea {
  padding-left: 35vw;
  padding-right: 35vw;
  padding-bottom: 4vh;
}

.row > * {
  /* padding-right: unset;
    padding-left: unset; */
  justify-content: center;
}

.cover {
  padding-bottom: 1vh;
}

span {
  padding: 1vh 0vw;
  font-weight: 700;
}

.ind > .icon {
  width: 5vw;
}

.ind {
  height: 6vh;
}

.-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 30%;
  margin: 0;
  padding: 0;
  padding-top: 2vh;
  margin-left: 2vw;
}

.event-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 1024px) {

  .gallery-cell {
    width: 50%;
  }
  .event-info {
    /* padding-top: 0px; */
    width: 100%;
  }

  .icon-title{
    padding-top: 4rem !important;
  }

  .is-selected > .gallery-desc> .desc-item > .icon{
    height: 98px;
  }

  .perks{
    right: .1rem;
    margin-top: -3.6rem;
  }

  .gallery-desc > .desc-item > .icon{
    height: 67px;
  }

  .carousel{
    padding: 0;
  }

  .icon-title{
    padding: 1rem 0;
  }

  .title-div, .icon-div{
    text-align: -webkit-center;
    width: 100%;
    padding-right: unset;
  }

  .title-div{
    padding: 20px 10px;
  }

  .offer-select{
    padding: .5rem;
  }
  .new-div, .renew-div{
    width: 100%;
    padding: 2.5vw;
    margin: 1rem;
  }
  .offer-select{
    padding-top: 3rem;
  }
  .gallery{
    z-index: -1;
  }
}
</style>
