<template>
  <div class="row let">
    <div class="row" style="padding-top: 4vh">
      <h1>ALPPS EVENTS</h1>
    </div>
    <div class="row main-featured">
      <div class="row">
        <h2><b>Featured Event</b></h2>
      </div>
      <div class="video">
        <div class="mapouter">
          <iframe class="main-video" src="https://drive.google.com/file/d/1O0SQHcmitCLKxtbKK7dRox10yizRFs79/preview" allow="autoplay"></iframe>
        </div>
      </div>
      <div class="event">
        <div class="event-details">
          <div class="event-info">
            <div class="event-title">
              <h2><b>Save the Date:<br>ALPPS General Assembly 2022</b></h2>
            </div>
            <div class="event-desc">
              <h4>10-04-2022</h4>
              <h3>
                Save the date! November 17 is ALPPS' 28th General Assembly. See you all at Mella Hotel. Video link is <a>here</a>.
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <h2><b>Other Events</b></h2>
      </div>

      <div class="row">
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1eA_39gAOZg-SH-f7mULBCHoztoGv2EyO/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">
              World Teachers' Day 2022
            </h3>
          </div>
          <div class="row">
            <p>Happy World Teachers’ Day to all the teachers! We love you, God bless you all. Video link is <a>here</a>.</p>
          </div>
        </div>
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1uPr_nC_q7bY3q6ZZEEyQ0-hS8RQ9f5MU/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">ALPPS Academic Literary Contests</h3>
          </div>
          <div class="row">
            <p>We know you're excited about our Academic Literary Contests, and so are we. Don't forget to keep... <a>See More</a></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1uBIEE6Rhs5lNBAOSrIw4dfpYZkYxPYn4/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">Palarong Malaki</h3>
          </div>
          <div class="row">
            <p>Here's what many of you've been waiting for...Palarong Malaki is now back! Staying safe online… <a>See More</a></p>
          </div>
        </div>
      </div>

      <div class="row" style="padding-top: 4vh">
        <h1>ALPPS ANNOUNCEMENTS</h1>
      </div>
      <div class="row">
        <h2><b>Featured Announcements</b></h2>
      </div>
      <div class="video">
        <div class="mapouter">
          <iframe class="main-video" src="https://drive.google.com/file/d/1eA_39gAOZg-SH-f7mULBCHoztoGv2EyO/preview" allow="autoplay"></iframe>
        </div>
      </div>
      <div class="event">
        <div class="event-details">
          <div class="event-info">
            <div class="event-title">
              <h2><b>FAQ on ALPPS General Assembly 2022</b></h2>
            </div>
            <div class="event-desc">
              <h4>10-17-2022</h4>
              <h3>
                In response to the questions of some schools, ALPPS would like to give some clarifications. Please watch the video and be guided accordingly, Thank you. Video link is <a>here</a>.
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <h2><b>Other Announcements</b></h2>
      </div>

      <div class="row">
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1zjN1QJVD7lEDhHrysUWlFMVuSfC0PFKV/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">DepEd Memo on Beginning of School Year (BOSY) Cutoff</h3>
          </div>
          <div class="row">
            <h3 class="event-date">08-10-2022</h3>
          </div>
          <div class="row">
            <p>
              Relative to this Memo, a division virtual meeting for PRIVATE SCH... <a>See More</a>.
            </p>
          </div>
        </div>
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1WCErhymLc2wQpUl07KvAl4-Qe_CTmibc/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">Amendment to DepEd Order No. 34-2022</h3>
          </div>
          <div class="row">
            <h3 class="event-date">08-10-2022</h3>
          </div>
          <div class="row">
            <p>
              DepEd has issued an Amendment to DepEd Order 34-2022, titled S... <a>See More</a>.
            </p>
          </div>
        </div>
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/15tEjc-jl5A9RHkfOBO-s0FHuzsmZ38YD/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">DepEd Memo 122-2022 on Observation of Deadlines</h3>
          </div>
          <div class="row">
            <h3 class="event-date">08-10-2022</h3>
          </div>
          <div class="row">
            <p>
              A reminder from DepEd on the OBSERVANCE of DEADLINES and TIMELINES... <a>See More</a>.
            </p>
          </div>
        </div>
        <div class="row tab">
          <div class="row other-evt-vid">
            <iframe src="https://drive.google.com/file/d/1tdK4jtbQ75o5pTFf7vdYJ4eA54Jcl2Qi/preview" width="560" height="255" allow="autoplay"></iframe>
          </div>
          <div class="row">
            <h3 class="event-title">CLARIFICATIONS FOR GEN ASSEMBLY</h3>
          </div>
          <div class="row">
            <p>
              Congratulations to the Fine Motor Skills winner in ALPPS Acade…
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNote",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  /* color: #fff; */
  vertical-align: text-top;
}

body{
  background: #F9F5F1;
}

.main-featured {
  padding: 0 4vw;
}

.other-evt-vid {
  padding: 2vh;
}

.video,
.event {
  display: inline-block;
  text-align: -webkit-center;
}

.video {
  padding: 3vh 0vw 3vh 4vw;
  width: 50%;
}

.event {
  width: 50%;
  padding-top: 3rem;
}

.icon {
  height: 100%;
}

.icon-img {
  width: 70px;
}

.event-details {
  width: 100%;
  margin-top: 0;
  padding-bottom: 4rem;
}

.event-icon,
.event-info {
  display: inline-block;
}

.event-icon {
  width: 20%;
}
.event-info {
  padding-top: 0px;
  width: 70%;
}

.main-video, .mapouter{
  width: 100%;
  height: 100% !important;
}

h1,
h2 {
  color: #5D4828;
}

h2,
h3 {
  text-align: left;
}

.let {
  /* background: #2E3192; */
  padding: 8vh 0vh;
  margin: 0;
}

h1 {
  font-size: 2em;
  font-family: MontserratBold, sans-serif;
}

h4 {
  text-align: left;
  /* padding: 3vh;
    width: 50vw;
    font-size: 1em; */
}

.btn-area {
  justify-content: center;
}

a {
  font-family: MontserratBold, sans-serif;
  /* margin-top: 1vh; */
  /* padding: 10px 20px; */
  /* width: auto; */
  /* font-size: 15px; */
  cursor: pointer;
  color: #000 !important;
  /* background: #f43ba9;
  border-radius: 5px; */
}
.elected {
  font-weight: 900;
  color: #EFD1A4;
}
.students {
  padding: 5vh 7vw;
}
.tab {
  width: 33.3%;
}

.environments,
.industries {
  padding: 5vh 10vw;
}

.icon {
  width: 20vw;
}

.row.tab > .row {
  justify-content: center;
}

.hrarea {
  padding-left: 35vw;
  padding-right: 35vw;
  padding-bottom: 4vh;
}

.row > * {
  /* padding-right: unset;
    padding-left: unset; */
  justify-content: center;
}

.cover {
  padding-bottom: 1vh;
}

span {
  padding: 1vh 0vw;
  font-weight: 700;
}

.ind > .icon {
  width: 5vw;
}

.ind {
  height: 6vh;
}

.-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 30%;
  margin: 0;
  padding: 0;
  padding-top: 2vh;
  margin-left: 2vw;
}

.event-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.event-date{
  font-size: 18px;
  text-align: center; 
}

@media only screen and (max-width: 1024px) {
  * {
    color: #000;
  }

  .let {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .video {
    padding: .5rem .3rem;
    width: 100%;
  }

  .event {
    width: 100%;
    padding-top: 1rem;
  }

  .event-info{
    width: 100%;
  }

  .main-featured{
    margin-top: 3rem;
  }

  .tab{
    width: 100%;
  }
}


</style>
