<template>
  <div id="becomeamember" class="book" :style="{ background: 'url(' + require('@/assets/graphics/bg-2nd.png') + ') no-repeat center center fixed' }">
    <BecomeAMember/> 
  </div>
</template>
<style>
  #becomeamember{
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
  .footer, .gallery{
    background: rgba(0,0,0,0) !important;
  }
</style>

<script>
// @ is an alias to /src
import BecomeAMember from '@/components/becomeamember/becomeamember.vue'

export default {
  name: 'Become a Member',
  components: {
    BecomeAMember,
  }
}
</script>
