<template>
  <div id="whatwedo" class="book" :style="{ background: 'url(' + require('@/assets/graphics/bg-2nd.png') + ') no-repeat center center fixed' }">
    <Whatwedo/> 
  </div>
</template>
<style>
  #whatwedo{
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
  .footer{
    background: rgba(0,0,0,0) !important;
  }
</style>
<script>
// @ is an alias to /src
import Whatwedo from '@/components/whatwedo/featured.vue'

export default {
  name: 'What We Do',
  components: {
    Whatwedo,
  }
}
</script>

