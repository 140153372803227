<template>
  <div class="row autom" id="lms">
    <div class="purplebg">

    </div>
    <div class="left">
      <div class="row screenshots">
        <img class="dashboard" src="@/assets/screenshots/stimulate.png" />
      </div>
    </div>
    <div class="right" >
      <div class="row">
        <a class="navbar-brand" href="#">
          <h1 class="left-title">
            <img class="quote-icon" src="@/assets/icons/qoute-brown.png" />
            <br><b>To stimulate a creative and competitive culture among the teachers and students by organizing inter school events.</b></h1>
        </a>
      </div>
      <!-- <div class="row">
        <h2 class="left-desc">Assists educational institutions in modernizing operations and enhancing community-wide engagement across a hyperconnected digital landscape.</h2>
      </div>
      <div class="row " >
        <h3 class="viewFeatures" @click="viewLMS">
          <span v-if="!showLms">+</span> 
          <span v-else>-</span> 
          <u><b>View Features</b></u>
        </h3>
      </div>
      <div class="row featuresDiv">
        <Transition>
          <ul v-if="showLms">
            <li>Online Assessments / Exams</li>
            <li>Student Progress Tracking</li>
            <li>Custom Grades Management</li>
            <li>Student Behavioral Profile</li>
            <li>Online Badges System</li>
            <li>Global Teacher and Student Community</li>
          </ul>
        </Transition>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolMngtSys',
  props: {
    // msg: String
  },
  data() {
    return {
      showLms: false
    }
  },
  methods: {
    viewLMS() {
      this.showLms = this.showLms ? false : true; 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .purplebg{
    width: 100%;
    height: 41vh;
    margin-top: 11vh;
    z-index: -1;
    background: #EFD1A4;
    position: absolute;
  }
  .quote-icon{
    max-width: 3rem;
  }
  .left-title{
    font-size: 2rem;
    font-family: MontserratLight, sans-serif;
    color: #000;
    white-space: initial;
    text-align: left;
  }
  .inputDemo{
    background: #fff;
    border: 1px solid #a9aba8;
    border-radius: 5px;
    width: 47%;
    margin-right: 10px;
  }
  .btn-area{
    padding-top: 5vh;
    justify-content: center;
  }
  .left-desc{
    font-size: 23px;
    font-family: MontserratRegular, sans-serif;
    color: #686868;
    width: 100%;
    text-align: right;
  }
  .viewFeatures{
    margin-top: 20px;
    font-size: 25px;
    font-family: MontserratBold, sans-serif;
    color: #000;
    text-align: right;
    cursor: pointer;
  }
  .autom{
      margin: 0;
      padding-top: 0px;
  }
  .right{
      width: 48vw;
      text-align: left;
      padding: 9vw 5vw;
      z-index: 2;
  }
  .left{
      width: 50vw;
  }

  .white, .strings{
      font-size: 1.5em;
  }

  .icon{
      width: auto;
      max-height: 30px;
  }

  h3{
      font-size: 1.7em;
      color: green;
      font-weight: 700;
  }

  .end{
      max-width: 31vw;
  }

  .screenshots{
      padding: 4vw;
  }

  .screenshots > img{
      /* position: absolute; */
  }

  .dashboard{
      width: 55.5vw;
      /* margin-top: 3.5vh; */
      /* right: 1vw; */
  }
  .row > ul > li {
    list-style: none;
    font-family: MontserratBold, sans-serif;
    color: #818181;
    text-align: right;
  }

  .featuresDiv{
    min-height: 174.062px;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  @media only screen and (max-width: 1024px) {
    .left, .right{
      width: 100%;
      display: inline-block;
    }
    
    .autom {
      padding-top: 0px;
      padding-bottom: 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: flex-start;
    }

    .autom .left {
      /* order: 2; */
    }

    .autom .right {
      /* order: 1; */
    }

    .screenshots > img{
      right: unset;
      display: block;
      position: relative;
      margin-top: 30px;
    }

    .dashboard{
      width: 90%;
    }

    .screenshots{
      display: block;
      text-align: -webkit-center;
    }

    .right{
      padding-left: 10vw;
      padding-right: 10vw;
    }

    .left-title{
      font-size: 30px;
      font-weight: 600 !important;
      white-space: break-spaces;
    }

    .left-desc{
      font-size: 25px;
    }

    .featuresDiv{
      min-height: 0px;
    }

    .purplebg{
      margin-top: 30px;
      height: 85vh;
    }
  }
</style>
