<template>
  <div class="row main-nav" id="main-nav">
    <nav class="navbar navbar-expand-lg navbar-light left-menu">
      <a class="navbar-brand" id="header-icon" :href="baseUrl">
        <img class="icon" src="@/assets/alpps-temp-logo.png" />
        <!-- <span class="good">white</span><span class="strings">Strings</span> -->
      </a>
      <button class="navbar-toggler" type="button" aria-label="Toggle navigation" @click="showMenu(true)">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item nav-common dropdown">
            <!-- <a class="nav-link" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              WHO WE ARE
            </a> -->
            <a :href="baseUrl" class="nav-link dropbtn">WHO WE ARE</a>
            <div class="dropdown-content">
              <a class="nav-link" :href="baseUrl+'#threestrat'">THREE CORE STRATEGIES</a>
              <a class="nav-link" :href="baseUrl+'#electedofficers'">ELECTED EXECUTIVE OFFICERS</a>
              <a class="nav-link" :href="baseUrl+'#contactus'">CONTACT US</a>
            </div>
          </li>
          <li class="nav-item nav-common">
            <a class="nav-link" :href="baseUrl+'/whatwedo'" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              WHAT WE DO
            </a>
          </li>
          <li class="nav-item nav-common">
            <a class="nav-link" :href="baseUrl+'/becomeamember'" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              BECOME A MEMBER
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link demoBtn" :href="baseUrl+'/new-membership'">REGISTER TODAY!</a>
          </li>
        </ul>
      </div>
    </nav>

    <div id="myNav" class="overlay" v-if="mobileMenu">
      <a class="closebtn" @click="showMenu(false)" >&times;</a>
      <div class="overlay-content">
        <a :href="baseUrl">WHO WE ARE</a>
        <a :href="baseUrl+'#electedofficers'">ELECTED EXECUTIVE OFFICERS</a>
        <a :href="baseUrl+'#contactus'">CONTACT US</a>
        <a :href="baseUrl+'whatwedo'">WHAT WE DO</a>
        <a :href="baseUrl+'becomeamember'">BECOME A MEMBER</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
      return {
          mobileMenu: false,
          baseUrl: window.location.origin,
          basePath: window.location.host,
      }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
      if(window.scrollY > 60){
        document.getElementById("main-nav").classList.add("nav-fixed");
      }else{
        document.getElementById("main-nav").classList.remove("nav-fixed");
      }
    },
    showMenu(setStatus){
      this.mobileMenu = setStatus;
    },
    gotoBase(){
      this.location = window.location.origin;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #header-icon{
    margin-right: 9rem;
  }

  .nav-common{
    font-family: MontserratLight, sans-serif;
  }

  .main-nav{
    padding-left: 6vw;
    padding-right: 1vw;
    margin: 0;
    background: #fff;
  }

  #navbarDropdown > svg{
      font-size: 9px;
  }

  .angle-down{
      color: green;
  }

  .nav-item{
      font-size: 11px;
      font-weight: 700;
      padding-left: 1.5vw;
      padding-right: 1.5vw;
  }

  .nav-link > .icon {
      height: 15px;
  }
  .navbar-brand > .icon {
      max-height: 40px;
  }

  .nav-link{
    font-size: 16px;
  }

  .good, .strings{
    color: #161418;
    font-size: 1.1em;
  }

  .strings{
    font-family: MontserratSemiBold, sans-serif;
  }

  .overlay {
    height: 100%;
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    backdrop-filter: blur(4px);
    z-index: 10;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #fff;
    display: block;
    transition: 0.3s;
  }

  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  .demoBtn{
    background: #5D4828;
  }

  @media only screen and (max-width: 1024px) {
    .main-nav{
      padding-left: 20px;
      padding-right: 20px;
      background: #fff;
    }
    #header-icon{
      margin-right: unset;
      float: left;
    }
    .navbar{
      display: block;
    }
    .navbar-toggler{
      float: right;
    }

    .overlay a {
      font-size: 35px;
      font-weight: 900;
    }
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }

  i.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {background-color: #f1f1f1;}

  .dropdown:hover .dropdown-content {
    display: block;
  }

</style>

