<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <h1>setup of home</h1> -->
    <!-- <TopNote/>  -->
    <!-- <Developing/>  -->
    <!-- <SchoolsReimagined/>  -->
    <FirstPart/>
    <!-- <Purpose/>   -->
    <Sustain/> 
    <Strengthen/>
    <Stimulate/>
    <Officers/>
    <ContactUs/>

    <!-- <Footer/> -->
    <!-- <LetUsKnow/>  -->
    <!-- <SchoolSmarter/>  -->
    <!-- <OurPartners/>  -->
    <!-- <MakingGreater/>  -->
    <!-- <AutomotiveJOSys/> 
    <SchoolMngtSys/> 
    <CmsConsultancySys/>  -->
  </div>
</template>

<script>
// @ is an alias to /src
// import TopNote from '@/components/TopNote.vue'
// import Developing from '@/components/home/Developing.vue'
import FirstPart from '@/components/home/firstPart.vue'
// import SchoolsReimagined from '@/components/home/SchoolsReimagined.vue'
// import Purpose from '@/components/home/Purpose.vue'
import Sustain from '@/components/home/Sustain.vue'
import Stimulate from '@/components/home/Stimulate.vue'
import Strengthen from '@/components/home/Strengthen.vue'
import Officers from '@/components/home/Officers.vue'
import ContactUs from '@/components/ContactUs.vue'
// import Footer from '@/components/home/Footer.vue'
// import LetUsKnow from '@/components/home/LetUsKnow.vue'
// import SchoolSmarter from '@/components/home/SchoolSmarter.vue'
// import OurPartners from '@/components/home/OurPartners.vue'
// import MakingGreater from '@/components/home/MakingGreater.vue'
// import SchoolMngtSys from '@/components/home/SchoolMngtSys.vue'
// import AutomotiveJOSys from '@/components/home/AutomotiveJOSys.vue'
// import CmsConsultancySys from '@/components/home/CmsConsultancySys.vue'
// import CreateHappyEnv from '@/components/home/CreateHappyEnv.vue'

export default {
  name: 'Home',
  components: {
    // TopNote,
    // Developing,
    // SchoolsReimagined,
    FirstPart,
    // Purpose,
    Sustain,
    Strengthen,
    Stimulate,
    Officers,
    ContactUs,
    // LetUsKnow,
    // SchoolSmarter,
    // OurPartners,
    // MakingGreater,
    // AutomotiveJOSys,
    // SchoolMngtSys,
    // CmsConsultancySys,
    // CreateHappyEnv,
    // Footer,
  },
  methods:{
     getCurrent: function() {
       if(this.$route.fullPath == ''){
          // const note = document.getElementsByClassName('main-nav');
          // note.style.backgroundColor = '#fff';

          document.getElementById("main-nav").style.background = "#effdec";
       }
     }
  },
  mounted(){
    this.getCurrent()
  },
}
</script>

<style>
</style>