<template>
  <div class="features">
    <FeatureFront/> 
    <ForSchoolLeaders/> 
    <ForTeachers/> 
    <ForStudents/> 
    <ForParents/> 
    <AchieveMore/> 
  </div>
</template>

<script>
// @ is an alias to /src
import FeatureFront from '@/components/features/FeatureFront.vue'
import ForSchoolLeaders from '@/components/features/ForSchoolLeaders.vue'
import ForTeachers from '@/components/features/ForTeachers.vue'
import ForStudents from '@/components/features/ForStudents.vue'
import ForParents from '@/components/features/ForParents.vue'
import AchieveMore from '@/components/features/AchieveMore.vue'

export default {
  name: 'Features',
  components: {
    FeatureFront,
    ForSchoolLeaders,
    ForTeachers,
    ForStudents,
    ForParents,
    AchieveMore,
  },
}

</script>
