import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Features from '../views/Features.vue'
import Member from '../views/Member.vue'
import What from '../views/What.vue'
import NewMembership from '../views/NewMembership.vue'
import RenewMembership from '../views/RenewMembership.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/features',
    name: 'features',
    component: Features
  },
  {
    path: '/becomeamember',
    name: 'member',
    component: Member
  },
  {
    path: '/new-membership',
    name: 'New Membership',
    component: NewMembership
  },
  {
    path: '/renew-membership',
    name: 'Renew Membership',
    component: RenewMembership
  },
  {
    path: '/whatwedo',
    name: 'book',
    component: What
  },
  // {
    // path: '/features',
    // name: 'features',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
