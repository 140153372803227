<template>
  <div class="row footer">
    <!-- <div class="row environments">
      <div class="row">
        <div class="row firsttab">
          <div class="row">
            <p class="lefttext">
              <b>Building the next generation of educated learners</b><br>
              <br>info@whitestrings.io
            </p>
          </div>
        </div>
        <div class="row tab">
          <ul>
            <li><a href="/"><b>Home</b></a></li>
            <li><a href="/#sms">School Management System</a></li>
            <li><a href="/#lms">Learning Management System</a></li>
            <li><a href="/#sma">Strings Mobile App</a></li>
            <li><a href="/#tfme">The future of Mobile Education</a></li>
          </ul>
        </div>
        <div class="row tab">
          <ul>
            <li><a href="/features"><b>Features</b></a></li>
            <li><a href="/features#fsl">For School Leaders</a></li>
            <li><a href="/features#ft">For Teachers</a></li>
            <li><a href="/features#fs">For Students</a></li>
            <li><a href="/features#fp">For Parents</a></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="row footer-area">
      <p class="footer-p">Copyright © 2022 ALPPS | Powered by whiteStrings.</p>
      <img class="footer-bg" src="@/assets/graphics/peoples.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .row > *{
    justify-content: normal !important;
  }
  *{
    color:#fff;
  }
  .lefttext{
    text-align: left;
    font-size: 14px;
    line-height: 14px;
    margin: unset;
  }
  .footer{
    margin: 0;
    padding: 17vh 5vh 0vh 5vh;
    /* padding: 5vh 5vw 15vh 5vw; */
    background: #fff;
    /* opacity: 0.49; */
  }
  .footer-area{
    position: relative;
  }
  .footer-bg, .footer-p{
    position: absolute;
    bottom: 0;
  }

  .firsttab{
    width: 37.3%;
  }
  .tab{
    width: 27.3%;
    padding-top: 5vh;
  }

  .environments, .industries{
    /* padding: 3vh 10vw; */
  }

  .icon{
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 5vw;
  }

  .row.tab > .row{
    justify-content: center;
  }

  .hrarea{
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 4vh;
  }

  .row > *{
    /* padding-right: unset;
    padding-left: unset; */
    justify-content: center;
  }

  ul{
    text-align: left;
  }

  ul>li{
    text-align: left;
    list-style: none;
    font-size: 14px;
  }



  .footer-p{
    float: left;
    text-align: left;
    color: #000;
    /* position: relative;
    display: block;
    width: 50%;
    bottom: 0;
    left: 0; */
  }

  .footer-bg{
    display: block;
    position: absolute;
    float: right;
    width: 50%;
    bottom: 0;
    right: 0;
  }

  @media only screen and (max-width: 1024px) {
    .footer{
      /* margin-top: 100px; */
      padding: 10vh 3vh 1vh 3vh;
    }

    .firsttab, .tab{
      width: 100%;
    }

    .tab{
      padding-top: 2vh;
    }
  }
</style>
