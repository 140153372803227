<template>
  <div class="row happy">
    <div class="row featureCntr" id="feature-mobile">
      <img class="featureBanner" src="@/assets/graphics/feature_mobile.png" />
    </div>
    <div class="row">
      <h2>white<b>Strings</b></h2>
      <h5>Helps schools manage, integrate and analyze their data better in a <i><b>Hyperconnected</b></i> environment in an affordable price.</h5>
    </div>
    <!-- <div class="row students">
      <img src="@/assets/graphics/students.svg" />
    </div> -->
    <div class="row featureCntr" id="feature-web">
      <img class="featureBanner" src="@/assets/graphics/feature.svg" />
    </div>
    <div class="row featureCntr">
      <a class="nav-link demoBtn" href="/book">Book a Demo Now</a>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureFront',
  props: {
    // msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .happy{
    margin-right: 0px;
    margin-left: 0px;
    /* height: 90vh; */
    padding-top: 15vh;
    padding-bottom: 20vh;
    justify-content: center;
  }
  .featureCntr{
    justify-content: center;
  }
  .featureBanner{
    width: 70vw;
  }
  .demoBtn{
    height: unset;
  }
  #feature-mobile{
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .happy{
      padding-top: 70px;
      padding-bottom: 70px;
      background: linear-gradient(180deg, rgba(239,253,236,1) 0%, rgba(239,253,236,1) 83%, rgba(255,255,255,1) 100%);
    }
    .featureBanner{
      width: 100vw;
    }
    #feature-web{
      display: none;
    }
    #feature-mobile{
      display: block;
    }
    .row > h2{
      margin-top: 30px;
      font-size: 45px;
      color: #3b3c3b;
    }
    .row > h2 > b{
      color: #222222;
    }

    h5{
      padding: 10px 15vw 30px 15vw;
      color: #222;
      font-size: 20px;
    }

    .demoBtn{
      background: #71f6ac;
    }

  }
</style>
