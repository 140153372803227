<template>
  <div class="row happy" id="electedofficers">
    <div class="row">
      <h1 class="elected"><b>ALPPS Officers for School Years 2021-2023</b></h1>
    </div>
    <!-- <div class="row students">
      <img src="@/assets/graphics/students.svg" />
    </div> -->
    <div class="row environments">
      <div class="row">
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/rowenalorenzo.png" />
          </div>
          <div class="row">
            <h3 class="position">President</h3>
          </div>
          <div class="row">
            <p>Ms. Rowena E. Lorenzo<br><i>Las Piñas Montessori School</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsgreen" src="@/assets/officers/rommelsomeros.png" />
          </div>
          <div class="row">
            <h3 class="position">Vice President-Internal</h3>
          </div>
          <div class="row">
            <p>Mr. Rommel M. Someros<br><i>AMA Basic Education</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/petronilotigaronita.png" />
          </div>
          <div class="row">
            <h3 class="position">Vice President-External</h3>
          </div>
          <div class="row">
            <p>Mr. Petronilo T. Tigaronita<br><i>St. Michael School</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsgreen" src="@/assets/officers/jhoannagonzales.png" />
          </div>
          <div class="row">
            <h3 class="position">Secretary</h3>
          </div>
          <div class="row">
            <p>Ms. Jhoanna Katrina O. Gonzales<br><i>South Champs Learning Center</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/milbertaguirre.png" />
          </div>
          <div class="row">
            <h3 class="position">Treasurer</h3>
          </div>
          <div class="row">
            <p>Mr. Milbert Aguirre<br><i>Camella School</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsgreen" src="@/assets/officers/donnafaithabad.png" />
          </div>
          <div class="row">
            <h3 class="position">Assistant Treasurer</h3>
          </div>
          <div class="row">
            <p>Donna Faith J. Abad<br><i>Academy of Jesus</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/kayezekielravago.png" />
          </div>
          <div class="row">
            <h3 class="position">Auditor</h3>
          </div>
          <div class="row">
            <p>Kay Ezekiel T. Ravago<br><i>South Learners Creative School</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsgreen" src="@/assets/officers/juliereyes.png" />
          </div>
          <div class="row">
            <h3 class="position">Assistant Auditor</h3>
          </div>
          <div class="row">
            <p>Dr. Julie H. Reyes<br><i>University of Perpetual Help</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/orangegarcia.png" />
          </div>
          <div class="row">
            <h3 class="position">Business Manager</h3>
          </div>
          <div class="row">
            <p>Orange V. Garcia<br><i>Our Lady of Pilar Montessori Center</i></p>
          </div>
        </div>
        <div class="row tab hiddentab">
          <div class="row">
            <img class="icon bsgreen" src="@/assets/officers/juliereyes.png"/>
          </div>
          <div class="row">
            <h3 class="position">Assistant Auditor</h3>
          </div>
          <div class="row">
            <p>Dr. Julie H. Reyes<br><i>University of Perpetual Help</i></p>
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon bsorange" src="@/assets/officers/nelsonuy.png" />
          </div>
          <div class="row">
            <h3 class="position">Communications Officer</h3>
          </div>
          <div class="row">
            <p>Mr. Nelson C. Uy<br><i>Jesus Cares Christian Academy</i></p>
          </div>
        </div>
        <div class="row tab hiddentab">
          <div class="row bsgreen">
            <img class="icon" src="@/assets/officers/juliereyes.png"/>
          </div>
          <div class="row">
            <h3 class="position">Assistant Auditor</h3>
          </div>
          <div class="row">
            <p>Dr. Julie H. Reyes<br><i>University of Perpetual Help</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .happy{
    margin: 0;
    padding-top: 10vh;
    /* margin-top: 10vh; */
    /* margin-bottom: 10vh; */
    background: #F9F5F1;
  }
  .elected{
    font-weight: 900;
    color: #5D4828;
  }
  .elected > b{
    font-weight: 900;
    color: #5D4828;
  }
  .students{
    padding: 5vh 7vw;
  }
  .tab{
    width: 33.3%;
  }
  .bsgreen{
    box-shadow: 12px 12px #8E9C8B;
  }
  .bsorange{
    box-shadow: 12px 12px #f2ba60;
  }
  .environments, .industries{
    padding: 5vh 10vw;
  }

  .icon{
    width: 20vw;
  }

  .row.tab > .row{
    justify-content: center;
  }

  .hrarea{
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 4vh;
  }

  .row > *{
    /* padding-right: unset;
    padding-left: unset; */
    justify-content: center;
  }

  .cover{
    padding-bottom: 1vh;
  }

  span{
    padding: 1vh 0vw;
    font-weight: 700;
  }

  .ind > .icon{
    width: 5vw;
  }

  .ind{
    height: 6vh;
  }

  .-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 30%;
    margin: 0;
    padding: 0;
    padding-top: 2vh;
    margin-left: 2vw;
  }
  

  .position{
    margin-top: 1.5rem;
    line-height: unset;
    font-size: 20px;
    font-weight: bold;
  }

  .hiddentab{
    visibility: hidden;
  }

  @media only screen and (max-width: 1024px) {
    .happy{
      padding-left: 10vw;
      padding-right: 10vw;
    }

    .happy > .row > h3{
      font-size: 30px;
      text-align: left;
      line-height: 42px;
    }

    .happy > .row > h4{
      text-align: left;
      margin-top: 10px;
    }
    
    .environments{
      padding: 0;
    }

    .environments > .row{
      display: block;
    }

    .tab{
      width: 100%;
      margin-top: 30px;
    }

    .icon{
      width: 80vw;
    }

    .tab > .row > h3{
      font-size: 30px;
    }

    .tab > .row > p{
      font-size: 20px;
    }

    .row > .row > h2{
      margin-top: 50px;
      font-size: 30px;
      position: absolute;
    }

    .hiddentab{
      visibility: unset;
      display: none;
    }
  }
</style>
